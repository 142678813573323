import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Attachment = ({ slug, title }) => (
  <S.Attachment href={slug} target="blank">
    <Card format="line" variant="neutral-1-20">
      <Content>
        <S.Content>
          <S.Icon>
            <Icon name="file" variant="primary-1" />
          </S.Icon>

          <S.Title>
            <Text>{title}</Text>
          </S.Title>

          <div>
            <Text variant="accent-1">Télécharger</Text>
          </div>
        </S.Content>
      </Content>
    </Card>
  </S.Attachment>
)

Attachment.propTypes = {
  /** Slug of the attachment. */
  slug: PropTypes.string.isRequired,
  /** Title of the attachment. */
  title: PropTypes.string.isRequired,
}

export default memo(Attachment)
